import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { createLogger } from "redux-logger";
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
import localStorage from "redux-persist/es/storage";
// import sessionStorage from "redux-persist/es/storage/session";
import userReducer from "./slice/userSlice";
import chatReducer from "./slice/chatSlice";
import tabsReducer from "./slice/tabsSlice"

const reducer = combineReducers({
  user : userReducer,
  chat : chatReducer,
  tabs : tabsReducer
 
});

// middleware that logs actions
// const logger = createLogger({ predicate: () => true });

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      // .concat(logger),
});
export const persistor = persistStore(store);

export default store;
