import React from 'react'
import plotImg from '../assets/img/plot-detail.png'

const PlotImg = () => {
  return (
    <>
    <div className='img-box me-2' style={{backgroundImage:`url(${plotImg})`}} >
    </div>
    </>
  )
}

export default PlotImg