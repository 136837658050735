import React from 'react'
import { AiFillPlusCircle } from "react-icons/ai";


const AddPropertyBtn = () => {
  return (
    <div className='icon-circle-property'><AiFillPlusCircle /></div>
  )
}

export default AddPropertyBtn