import React, {useEffect, useState} from 'react'
import rightArrowCircleImg from '../assets/img/right-arrow-circle.png'
import sendIcon from '../assets/img/send.png'
import { useDispatch, useSelector } from "react-redux";
import { reqToChat } from "./../reduxToolkit/services/chatServices"
const SearchBox = (props) => {
  const { searchtext } = props;
  const [search, setSearch] = useState('');
  const [user, setUser] = useState();
  const dispatch = useDispatch();
  const sendhandler = async () =>{
    searchtext(search);
    dispatch(reqToChat({ question: search  }));
    setSearch('');
    //searchtext('');
  }
  const handleKeyDown = async(e) => {
    
    if (e.key === 'Enter') {
      searchtext(search);
      dispatch(reqToChat({ question: search, phone : user?.phone  }));
      setSearch('');
      //searchtext('');
    }
  }
  useEffect(() => {
    if(localStorage.getItem('userData')) {
        const userData = JSON.parse(localStorage.getItem('userData') || "");
        setUser(userData)
      } 
    
},[localStorage.getItem('userData')]);
  return (
    <div className='searchBox'>
        <div className='input-container'>
            <input type='text' value={search} onChange={(e)=>setSearch(e.target.value)} onKeyDown={handleKeyDown} placeholder='Ask about property'/>
            <span className='icon-circle dark-blue-back'>
            <img src={sendIcon} onClick={sendhandler} />
            </span>
        </div>
    </div>
  )
}
export default SearchBox;