import React from 'react'
import plotImg from '../assets/img/plot.jpeg'
import { Link } from 'react-router-dom'
import soldImg from '../assets/img/sold.png'
import purchasedImg from '../assets/img/purchased.png'
import vacantImg from '../assets/img/vacant.png'
import rentedImg from '../assets/img/rented.png'
import { FaHeart } from "react-icons/fa";


const PropertyBox = (props) => {
  return (
    <div className='property-box mb-2'  style={{backgroundImage:`url(${plotImg})`}}>
        {
            props.type == 'purchased' ?
            <img src={purchasedImg} /> : props.type == 'sold' ?
            <img src={soldImg} /> : props.type == 'vacant' ?
            <img src={vacantImg} /> : props.type == 'rented' ?
            <img src={rentedImg} /> : ''
        }
        <span className='heart-circle'>
        <FaHeart />
        </span>
        <Link to=''>Get More Details </Link>
    </div>
  )
}

export default PropertyBox