import axios from "axios";
import { API_URL } from "../../config";

export const authHeaders = () => {
  const token = localStorage.getItem("persist:user");
  const parsedData = JSON.parse(token);
  let a = JSON.parse(parsedData.user);
  const userToken = parsedData && parsedData.user ? JSON.parse(parsedData.user).token : null;

  return {
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-Type': 'application/json',
    },
  };
};

export const authHeadersIMage = () => {
  const token = localStorage.getItem("persist:user");
  const parsedData = JSON.parse(token);
  let a = JSON.parse(parsedData.user);
  const userToken = parsedData && parsedData.user ? JSON.parse(parsedData.user).token : null;

  return {
    headers: {
      Authorization: `Bearer ${userToken}`,
      "Content-Type": "multipart/form-data",
    },
  };
};

const Axios = axios.create({
  baseURL: `${API_URL}`,
});

export default Axios;
