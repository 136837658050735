import React from 'react'
import Header from '../layout/Header'
import Container from 'react-bootstrap/esm/Container'
import SearchBox from '../component/SearchBox'
import PageContent from '../component/PageContent'
import LoginModal from '../component/LoginModal'
import OtpModal from '../component/OtpModal'
import ExploreBox from '../component/ExploreBox'
import { Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux";
import ChartIcon from '../assets/img/chart-icon.png'
import LaunchIcon from '../assets/img/launch-icon.png'
import PlotIcon from '../assets/img/plot-icon.png'
import PossessionIcon from '../assets/img/possession-icon.png'
import Question from '../component/Question'
import Answer from '../component/Answer'
import PlotBox from '../component/PlotBox'
import ScrollX from '../component/ScrollX';
import PageTabs from '../component/PageTabs';
import TableVertical from '../component/TableVertical';
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { reqToInitialMsg, reqToChatHistories } from "../reduxToolkit/services/chatServices";
import Property from '../component/Property';
const Home = () => {
  const chat = useSelector((state) => state?.chat?.chat);
  const histories = useSelector((state) => state?.chat?.histories);
  const loader = useSelector((state) => state?.chat?.loader);
  const initMsg = useSelector((state) => state?.chat?.initMsg);
  const trands = useSelector((state) => state?.tabs?.trands);
  const tax = useSelector((state) => state?.tabs?.tax);
  const documents = useSelector((state) => state?.tabs?.documents);
  const visa = useSelector((state) => state?.tabs?.visa);
  const nearplace = useSelector((state) => state?.tabs?.nearplace);
  const foreignEligibility = useSelector((state) => state?.tabs?.foreignEligibility);
  const roi = useSelector((state) => state?.tabs?.roi);
  const tabQue = useSelector((state) => state?.tabs?.tabQue);
  const loader2 = useSelector((state) => state?.tabs?.loader);
  const scrollRef = useRef();
  const navigate = useNavigate(); 
  const [messages, setMessages] = useState([]);
  const [search, setSearch] = useState('');
  const [user, setUser] = useState();
  const ansText = `To find the best real estate near your location, there are a few factors to consider. According to a source on real estate, the location of a property is key to its value. Homes in cities with limited room for expansion tend to be more valuable than those in cities with plenty of room. It's also important to consider the accessibility, appearance, and amenities of a neighborhood, as well as plans for development. Additionally, the proximity of a property to busy roadways may affect its desirability for resale. Acreage often trumps the quality of a house because land tends to increase in value.
  `;
  const dispatch = useDispatch();
  useEffect(()=>{
   
    if(search != ''){
      const msgs = [...messages];
      msgs.push({msg : search, type : 'text', sender : 'user',json_data : [], phone : user?.phone});
      setMessages(msgs);
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
      setSearch('');
    }
  },[search]);
  console.log({user});
  useEffect(()=>{
   
    if(histories.length > 0){
      
      setMessages(histories?.data || []);
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
      setSearch('');
    }
    
    
  },[histories]);

  useEffect(()=>{
   
    if(tabQue != ''){
      const msgs = [...messages];
      msgs.push({msg : tabQue, type : 'text', sender : 'user',json_data : [], phone : user?.phone});
      setMessages(msgs);
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  },[tabQue]);
  
  useEffect(()=>{
    if(chat){
      const msgs = [...messages];
      msgs.push({msg : chat?.message, type : chat.type, sender : 'bot', json_data : chat?.data, phone : user?.phone});
      setMessages(msgs);
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }
   
  },[chat]);
  useEffect(()=>{
    if(trands){
     
      const msgs = [...messages];
      msgs.push({msg : trands?.message, type : trands.type, sender : 'bot', json_data : trands?.data, phone : user?.phone});
      setMessages(msgs);
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }
   
  },[trands]);
  useEffect(()=>{
    if(tax){
      const msgs = [...messages];
      msgs.push({msg : tax?.message, type : tax.type, sender : 'bot', json_data : tax?.data, phone : user?.phone});
      setMessages(msgs);
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }
   
  },[tax]);
  useEffect(()=>{
    if(documents){
      const msgs = [...messages];
      msgs.push({msg : documents?.message, type : documents.type, sender : 'bot', json_data : documents?.data, phone : user?.phone});
      setMessages(msgs);
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  },[documents]);
  useEffect(()=>{
    if(visa){
      const msgs = [...messages];
      msgs.push({msg : visa?.message, type : visa.type, sender : 'bot', json_data : visa?.data, phone : user?.phone});
      setMessages(msgs);
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  },[visa]);
  useEffect(()=>{
    if(nearplace){
      const msgs = [...messages];
      msgs.push({msg : nearplace?.message, type : nearplace.type, sender : 'bot', json_data : nearplace?.data, phone : user?.phone});
      setMessages(msgs);
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  },[nearplace]);
  useEffect(()=>{
    if(foreignEligibility){
      const msgs = [...messages];
      msgs.push({msg : foreignEligibility?.message, type : foreignEligibility.type, sender : 'bot', json_data : foreignEligibility?.data, phone : user?.phone});
      setMessages(msgs);
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  },[foreignEligibility]);

  useEffect(()=>{
    if(roi){
      const msgs = [...messages];
      msgs.push({msg : roi?.message, type : roi.type, sender : 'bot', json_data : roi?.data, phone : user?.phone});
      setMessages(msgs);
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  },[roi]);
  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);
  useEffect(() => {
    if(localStorage.getItem('userData')) {
        const userData = JSON.parse(localStorage.getItem('userData') || "");
        console.log("user Data ", userData);
        setUser(userData);
    }else {
        setTimeout(() => {
        navigate('/login')
      }, 500);
    }
    
  },[localStorage.getItem('userData')]);
  
  useEffect(()=>{
    if(messages.length == 0 && !initMsg){
     // dispatch(reqToInitialMsg());
    }
  },[messages, initMsg]);
  useEffect(()=>{
    if(messages.length == 0){
      dispatch(reqToChatHistories({phone : user?.phone}));
  
   }
  },[messages, user]);
  useEffect(()=>{
    if(initMsg){
      const msgs = [...messages];
      msgs.push({msg : initMsg?.message, type : initMsg.type, sender : 'bot', json_data : [], phone : user?.phone});
      setMessages(msgs);
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  },[initMsg]);
  console.log({messages});
  return (
    <div className='content'>
      <div className='page-wrapper mx-auto'>
        <div className='page-content'>
          <h3>Use <span className='cyne-blue'>Globe Hive</span> to access Dubai properties for good return of investment and visa benefits</h3>
          <br />
          <h2 className='font-montserrat'>Explore</h2>
          <Row>
            <Col xs={6} className='mb-2'>
              <ExploreBox
                icon={ChartIcon}
                title='Trending Property'
                desc='Look for best real state Near my location'
              />
            </Col>
            <Col xs={6} className='mb-2'>
              <ExploreBox 
                icon={LaunchIcon} 
                title='New launched' 
                desc='What makes this newly launched property a promising investment opportunity?'
              />
            </Col>
            <Col xs={6} className='mb-2'>
              <ExploreBox 
                icon={PlotIcon} 
                title='Open Plots' 
                desc='What amenities and future developments are planned for the area surrounding these open plots?'
              />
            </Col>
            <Col xs={6} className='mb-2'>
              <ExploreBox 
                icon={PossessionIcon} 
                title='Ready Possession' 
                desc='What advantages does purchasing a property with ready possession offer to potential buyers?'
              />
            </Col>
          </Row>

          {/* <Question text={`I'm looking for a Plot near of Surat.`} />
         <Answer text={ansText} />
         <p className='golden underline mt-2 mb-1'>Plots for Sale in Surat</p> */}
         {/* <ScrollX>
            <PlotBox />
            <PlotBox />
            <PlotBox />
         </ScrollX>
          <PageTabs />
          <Question text={`I'm looking for a Plot near of Surat.`} />
         <Answer text={ansText} />
         <p className='golden underline mt-2 mb-1'>Plots for Sale in Surat</p>
         <ScrollX>
            <PlotBox />
            <PlotBox />
            <PlotBox />
         </ScrollX> */}
         {/* <Question text={`I'm looking for a Plot near of Surat.`} />
         <Answer text={ansText} />
         <Question text={`I'm looking for a Plot near of Surat.`} />
         <Answer text={ansText} />
         <TableVertical /> */}
         {/* <Property />
         <Property /> */}
        
        
         {/***********Chat Start*******************/}
         {messages?.map((msg, index)=>{
           if(msg?.sender == "user"){
              return(<div ref={scrollRef}><Question text={msg?.msg}  /></div>)
           }else{
              if(msg?.type == 'text'){
                return(<div ref={scrollRef}><Answer text={msg?.msg} /></div>);
              }
              if(msg?.type == 'property'){
                return (<div ref={scrollRef}><Property property={msg?.json_data} /></div>);
                // Promise.all(msg?.json_data?.map(async(propert)=>{
                //   console.log({propert})
                //   return (<Answer text={ansText} />)
                // }))

              }
           }
         })}
         {/***********Chat End*******************/}
         {loader &&<div class="loader" ref={scrollRef}></div>}
         {loader2 &&<div class="loader" ref={scrollRef}></div>}
        </div>
        <SearchBox searchtext={setSearch} />
      </div>
    </div>
  )
}

export default Home