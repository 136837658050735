import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import LogoHorizontal from '../logo.png'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import ContryCodeInput from '../component/ContryCodeInput';
import Button from 'react-bootstrap/Button';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import {toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import { reqToLoginOtp } from "./../reduxToolkit/services/userServices"
const Login = (props) => {
    const [show, setShow] = useState(true);
    const [phone, setPhone] = useState();
    const [countryCode, setCountryCode] = useState('+1');
    const [phoneNumber, setPhoneNumber] = useState('');
    const navigate = useNavigate(); 
    const dispatch = useDispatch();
   
    
      const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event);
      };
      const sendotpHandler = async (e)=>{
        e.preventDefault();

        var patt = new RegExp(/^[2-9]\d{2}[2-9]\d{2}\d{4}$/);

        let isValid = true;
       if (phoneNumber.trim().length === 0) {
        toast.error("Please Enter You Phone Number");
        isValid = false;
      }
      if (phoneNumber.trim().length < 10) {
        toast.error("Please Enter Valid Phone Number");
        isValid = false;
      }
      if(isValid){
        await dispatch(reqToLoginOtp(phoneNumber));
        navigate('/otp');
      }
      console.log("Res : ", phoneNumber.trim().length)
      //
        console.log("Logs.....");
      }
      useEffect(() => {
        if(localStorage.getItem('userData')) {
            const userData = JSON.parse(localStorage.getItem('userData') || "");
            console.log({userData});
            setTimeout(() => {
              navigate('/')
            }, 500);
        } else {
            setTimeout(() => {
            //  navigate('/login')
            }, 500);
        }
        
    },[localStorage.getItem('userData')]);
  return (
    <>
      <Container fluid className='login-page'>
       
          <div lg={4} className='login-box'>
            <img src={LogoHorizontal} />
            <p className='color-white mt-4'>Sign Up to Your Account</p>
            {/* <ContryCodeInput value={countryCode} onChange={handleCountryCodeChange} /> */}
            
            <div lg={4} className=''>
                <PhoneInput
                  inputProps={{
                    name: 'phone',
                    required: true,
                    autoFocus: true
                  }}
                  onChange={handlePhoneNumberChange}
                  
                />
                  <Button className='login-btn' onClick={sendotpHandler}> Send OTP</Button>
            </div>
          
          </div>
      </Container>
    </>
  )
}

export default Login