import React from 'react'
import { Col } from 'react-bootstrap'
import ChartIcon from '../assets/img/chart-icon.png'

const ExploreBox = (props) => {
  return (
    <>
    <div className='explore-box'>
        <img  src={props.icon} />
        <div className='title'>{props.title}</div>
        <div className='Discription'>{props.desc}</div>
    </div>
    </>
  )
}

export default ExploreBox