import React, { useEffect, useState } from 'react'
import LogoHorizontal from '../logo.png'
import "react-phone-input-2/lib/bootstrap.css";
import Button from 'react-bootstrap/Button';
import { Col, Container, Row } from 'react-bootstrap';
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {toast } from 'react-toastify';
import { reqToUserLogin } from "./../reduxToolkit/services/userServices";
const Otp = (props) => {
    const [show, setShow] = useState(true);
    const [otp, setOtp] = useState('');
    const [phone, setPhone] = useState('');
    const loginPhone = useSelector((state) => state?.user?.phone);
    const navigate = useNavigate();
    const dispatch = useDispatch(); 
    const [fixOTP, setFixOTP] = useState(123456)
  useEffect(()=>{
    if(loginPhone){
      setPhone(loginPhone)
    }else{
      navigate('/login');
    }
  },[loginPhone]);
  const verifyOtpHandler = async (e) =>{
    e.preventDefault();

       

        let isValid = true;
       if (otp.trim().length === 0) {
        toast.error("Please Enter Otp");
        isValid = false;
      }
      if (otp.trim() != fixOTP) {
        toast.error(" Otp Did not Matched");
        isValid = false;
      }
      if(isValid){
        await dispatch(reqToUserLogin({
          phone, otp
        }));
        navigate('/');
      }
  }
  const handleKeyDown = async(e) => {
    
    if (e.key === 'Enter') {
      let isValid = true;
       if (otp.trim().length === 0) {
        toast.error("Please Enter Otp");
        isValid = false;
      }
      if (otp.trim() != fixOTP) {
        toast.error(" Otp Did not Matched");
        isValid = false;
      }
      if(isValid){
        await dispatch(reqToUserLogin({
          phone, otp
        }));
        navigate('/');
      }
    }
  }
  useEffect(() => {
    if(localStorage.getItem('userData')) {
        const userData = JSON.parse(localStorage.getItem('userData') || "");
        console.log({userData});
        setTimeout(() => {
          navigate('/')
        }, 500);
    } else {
        setTimeout(() => {
         // navigate('/login')
        }, 500);
    }
    
},[localStorage.getItem('userData')]);
  return (
    <>
      <Container fluid className='login-page'>
       
          <div lg={4} className='login-box'>
            <img src={LogoHorizontal} />
            <p className='color-white mt-4 text-left'>Enter 6 digit verification code sent to your phone number <br/><span style={{color:'#de3d3d',fontSize:'15px'}}> For Now otp is (123456)</span></p>
            {/* <ContryCodeInput value={countryCode} onChange={handleCountryCodeChange} /> */}
            <div className='otp-wrapper otp-input'>
               
               <OtpInput
                value={otp}
                onChange={setOtp}
                onKeyDown={handleKeyDown}
                numInputs={6}
                inputType="number"
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
              />
            </div>
            
            <a href="#" className='resend-link'>Resend Code</a>
            <Button className='login-btn' onClick={verifyOtpHandler}> Verify OTP</Button>
          </div>
      </Container>
    </>
  )
}

export default Otp