import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../helper/axios";
import { apiendpoints, messages, routes } from "../../constants";


//LoginUser
export const reqToMarketTrand = createAsyncThunk("reqToMarketTrand", async (data) => {
  try {
    const response = await Axios.post(apiendpoints.getTrands, data);
    return response.data;
  } catch (error) {
    console.error("err =>", error);
   
    throw error;
  }
});

export const reqToPropertyTax = createAsyncThunk("reqToPropertyTax", async (data) => {
    try {
      const response = await Axios.post(apiendpoints.getTax, data);
      return response.data;
    } catch (error) {
      console.error("err =>", error);
     
      throw error;
    }
});

export const reqToForeignEligibility = createAsyncThunk("reqToForeignEligibility", async (data) => {
    try {
      const response = await Axios.post(apiendpoints.getforeignEligibility, data);
      return response.data;
    } catch (error) {
      console.error("err =>", error);
     
      throw error;
    }
});

export const reqToROI = createAsyncThunk("reqToROI", async (data) => {
    try {
      const response = await Axios.post(apiendpoints.getInvestment, data);
      return response.data;
    } catch (error) {
      console.error("err =>", error);
     
      throw error;
    }
});
export const reqToNearbyAmenities = createAsyncThunk("reqToNearbyAmenities", async (data) => {
    try {
      const response = await Axios.post(apiendpoints.getNearPlace, data);
      return response.data;
    } catch (error) {
      console.error("err =>", error);
     
      throw error;
    }
});

export const reqToLegalDocuments = createAsyncThunk("reqToLegalDocuments", async (data) => {
    try {
      const response = await Axios.post(apiendpoints.getDocument, data);
      return response.data;
    } catch (error) {
      console.error("err =>", error);
     
      throw error;
    }
});

export const reqToVISABenefits = createAsyncThunk("reqToVISABenefits", async (data) => {
    try {
      const response = await Axios.post(apiendpoints.getVisa, data);
      return response.data;
    } catch (error) {
      console.error("err =>", error);
     
      throw error;
    }
});

export const reqToTabQuestion = createAsyncThunk("reqToTabQuestion", async (data) => {
  try {
    //const response = await Axios.post(apiendpoints.getVisa, data);
    return data?.question;
  } catch (error) {
    console.error("err =>", error);
   
    throw error;
  }
});