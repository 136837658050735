import React from 'react'
import PropertyBox from '../component/PropertyBox'

const Favorites = () => {
  return (
    <div className='content'>
        <div className='page-wrapper mx-auto'>
            <div className='page-content'>
                <h2 className='m-0'>Property Type</h2>
                <hr className='title-underline'/>
                <PropertyBox  isFav={true}/>
                <PropertyBox  isFav={true}/>
                <PropertyBox  isFav={true}/>
            </div>
        </div>
    </div>
  )
}

export default Favorites