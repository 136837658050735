import React from 'react'

const Question = (props) => {
  return (
    <div className='question-wrapper'>
        <div className='question'>{props.text}</div>
       
    </div>
  )
}

export default Question