import React from 'react'

const History = () => {
  return (
    <>
         <div className='content'>
            <div className='page-wrapper mx-auto'>
                <div className='page-content'>
                <h2 className='m-0'>History</h2>
                <hr className='title-underline'/>
                <div className='chat-item'>
                    <div className='title'>New Chat</div>
                    <div className='desk'>AI Chatbot Assistant — Figma Resources ..........</div>
                    <div className='date-time'>30 Sep 2023,  12:22PM</div>
                </div>
                <div className='chat-item'>
                    <div className='title'>New Chat</div>
                    <div className='desk'>AI Chatbot Assistant — Figma Resources ..........</div>
                    <div className='date-time'>30 Sep 2023,  12:22PM</div>
                </div>
                <div className='chat-item'>
                    <div className='title'>New Chat</div>
                    <div className='desk'>AI Chatbot Assistant — Figma Resources ..........</div>
                    <div className='date-time'>30 Sep 2023,  12:22PM</div>
                </div>
                <div className='chat-item'>
                    <div className='title'>New Chat</div>
                    <div className='desk'>AI Chatbot Assistant — Figma Resources ..........</div>
                    <div className='date-time'>30 Sep 2023,  12:22PM</div>
                </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default History