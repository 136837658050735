import React, { useEffect } from 'react'
import LogoHorizontal from '../yogreet-logo-horizontal.png'
import LogoVertical from '../yogreet-logo-vertical.png'
import LogoTrim from '../logo-trim.png'
import AddCircleImg from '../assets/img/add-circle_svg.png'
import UserImg from '../assets/img/user_svg.png'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ChatImg from '../assets/img/chat-round-line_svg.png'
import HistoryImg from '../assets/img/history_svg.png'
import PropertyImg from '../assets/img/property_svg.png'
import FavoriteImg from '../assets/img/favourite_svg.png'


const Header = () => {
  useEffect(() => {
    if(localStorage.getItem('userData')) {
        const userData = JSON.parse(localStorage.getItem('userData') || "");
        
        setTimeout(() => {
            
        }, 500);
    } else {
        setTimeout(() => {
           
        }, 500);
    }
    
},[localStorage.getItem('userData')]);
  return (
    <>
      <div className='header'>
            <div className='menu-item logo-item'>
                <img className='logo desk-hide' src={LogoVertical} /> 
                <img className='logo mobile-hide' src={LogoTrim} /> 
            </div>
            
            <div className='menu-item'>
              <span className='icon-circle golden-back'>
                <img className='menu-icon' src={AddCircleImg} /> 
              </span>
                <span className='menu-name'>New Chat</span>
            </div>

            <div className='menu-item'>
                <span className='icon-circle cyne-blue-back'>
                  <img className='menu-icon' src={UserImg} /> 
                </span>
                <span className='menu-name'>Profile</span>
            </div>

            <div className='menu-item mobile-hide active'>
              <span className='icon-circle cyne-blue-back'>
                <img className='menu-icon' src={ChatImg} /> 
              </span>
              <span className='menu-name'>Chat</span>
            </div>
            <div className='menu-item mobile-hide'>
              <span className='icon-circle cyne-blue-back'>
                <img className='menu-icon' src={HistoryImg} /> 
              </span>
              <span className='menu-name'>History</span>
            </div>
            <div className='menu-item mobile-hide'>
              <span className='icon-circle cyne-blue-back'>
                <img className='menu-icon' src={PropertyImg} /> 
              </span>
              <span className='menu-name'>My Property</span>
            </div>
            <div className='menu-item mobile-hide'>
              <span className='icon-circle cyne-blue-back'>
                <img className='menu-icon' src={FavoriteImg} /> 
              </span>
              <span className='menu-name'>Favorite</span>
              
            </div>
      </div>
      <div className='footer-menu desk-hide'>
        <div className='menu-item active'>
          <img src={ChatImg} /> 
          <span className='menu-name'>Chat</span>
        </div>
        <div className='menu-item'>
          <img src={HistoryImg} /> 
          <span className='menu-name'>History</span>
        </div>
        <div className='menu-item'>
          <img src={PropertyImg} /> 
          <span className='menu-name'>My Property</span>
        </div>
        <div className='menu-item'>
          <img src={FavoriteImg} /> 
          <span className='menu-name'>Favorite</span>
        </div>
      </div>
    </>
  )
}

export default Header