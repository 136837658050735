import React, {useEffect, useState} from 'react'
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import { 
  reqToMarketTrand,
  reqToPropertyTax,
  reqToForeignEligibility,
  reqToROI,
  reqToNearbyAmenities,
  reqToLegalDocuments,
  reqToVISABenefits,
  reqToTabQuestion
 } from "./../reduxToolkit/services/tabsServices";
import { useDispatch, useSelector } from "react-redux";
const PageTabs = (props) => {
  const { proprt } = props;
  const dispatch = useDispatch();
  const [user, setUser] = useState();
  const getMarketTrands = async () =>{
    dispatch(reqToTabQuestion({
      question : `How is the current real estate market in ${proprt?.address} for foreign investors, especially for those interested in ${proprt?.property_type}, ${proprt?.property_size}?`
    }));
    dispatch(reqToMarketTrand({
      property : proprt,
      phone : user?.phone,
      question : `How is the current real estate market in ${proprt?.address} for foreign investors, especially for those interested in ${proprt?.property_type}, ${proprt?.property_size}?`
    }))
  }
  const getTax = async () =>{
    dispatch(reqToTabQuestion({
      question : `what are the property tax implications for a foreign buyer considering a purchase in ${proprt?.address}, particularly for ${proprt?.property_type}?`
    }));
    dispatch(reqToPropertyTax({
      property : proprt,
      phone : user?.phone,
      question : `what are the property tax implications for a foreign buyer considering a purchase in ${proprt?.address}, particularly for ${proprt?.property_type}?`
    }))
  }
  const getForeignEligibility = async ()=>{
    dispatch(reqToTabQuestion({
      question : `What are the essential legal documents and procedures for a foreign buyer purchasing ${proprt?.property_type} in${proprt?.address}?`
    }));
    dispatch(reqToForeignEligibility({
      property : proprt,
      phone : user?.phone,
      question : `What are the essential legal documents and procedures for a foreign buyer purchasing ${proprt?.property_type} in${proprt?.address}?`
    }))
  }
  const getRoi = async () =>{
    dispatch(reqToTabQuestion({
      question : `can you analyze the ROI for ${proprt?.property_type} in ${proprt?.address} within ${proprt?.price}?`
    }));
    dispatch(reqToROI({
      property : proprt,
      phone : user?.phone,
      question : `can you analyze the ROI for ${proprt?.property_type} in ${proprt?.address} within ${proprt?.price}?`
    }))
  }
  const getAmenities = async ()=>{
    dispatch(reqToNearbyAmenities({
      property : proprt,
      phone : user?.phone
    }))
  }
  const getDocumnts = async () =>{
    dispatch(reqToTabQuestion({
      question : `In ${proprt?.address}, what are the essential legal documents and procedures for foreign buyers purchasing ${proprt?.property_type}? How can one prepare for a smooth transaction process amidst specific legal hurdles or documentation requirements?`
    }));
    dispatch(reqToLegalDocuments({
      property : proprt,
      phone : user?.phone,
      question : `In ${proprt?.address}, what are the essential legal documents and procedures for foreign buyers purchasing ${proprt?.property_type}? How can one prepare for a smooth transaction process amidst specific legal hurdles or documentation requirements?`
    }))
  }
  const getVisabenefits = async () =>{
    dispatch(reqToTabQuestion({
      question : `Could you inform me about any visa benefits or residency opportunities available through property investment in ${proprt?.address}, including the minimum investment required and the application process?`
    }));
    dispatch(reqToVISABenefits({
      property : proprt,
      phone : user?.phone,
      question : `Could you inform me about any visa benefits or residency opportunities available through property investment in ${proprt?.address}, including the minimum investment required and the application process?`
    }))
  }
  useEffect(() => {
    if(localStorage.getItem('userData')) {
        const userData = JSON.parse(localStorage.getItem('userData') || "");
        setUser(userData)
      } 
    
},[localStorage.getItem('userData')]);
return (
    
    <Stack className='page-tabs' direction="horizontal" gap={2}>
        <Badge bg="cyne-blue" onClick={getMarketTrands}>Market Trands</Badge>
        <Badge bg="cyne-blue" onClick={getTax}>Property Tax</Badge>
        <Badge bg="cyne-blue" onClick={getForeignEligibility}>Foreign Eligibility</Badge>
        <Badge bg="cyne-blue" onClick={getRoi}>ROI (Rental Income)</Badge>
        {/* <Badge bg="cyne-blue" onClick={getAmenities}>Nearby Amenities</Badge> */}
        <Badge bg="cyne-blue" onClick={getDocumnts}>Legal Documents</Badge>
        <Badge bg="cyne-blue" onClick={getVisabenefits}>VISA Benefits</Badge>
    </Stack>
    
  )
}

export default PageTabs