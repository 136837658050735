import React from 'react'
import Table from 'react-bootstrap/Table';

const TableVertical = () => {
  return (
    <Table striped variant="dark" className='mt-2 mb-2' style={{borderRadius: '4px', overflow: 'hidden'}}>
      <thead>
        <tr>
          <th>Aspect</th>
          <th>Surat (₹10/sq. m/annum)</th>
          <th>Navsari (₹8/sq. m/annum)</th>
          <th>Difference (Surat - Navsari)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Property Tax Rate</td>
          <td>₹10/sq. m/annum</td>
          <td>₹8/sq. m/annum</td>
          <td>₹2/sq. m/annum</td>
        </tr>
      </tbody>
    </Table>
  )
}

export default TableVertical