import { createSlice } from "@reduxjs/toolkit";
import {
  reqToUserLogin,
  reqToLoginOtp
} from "../services/userServices";

// Create initial state
const initialState = {
  loader: false,
  user: null,
  phone: false,
  userDetail: {},
  error: "",
};

// Create user slice
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reqToUserLogout: () => {
    
      return initialState;
    },
  },
  extraReducers: (builder) => {
    //registerUser
    
    //reqToUserLogin
    builder.addCase(reqToUserLogin.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToUserLogin.fulfilled, (state, action) => {
      state.loader = false;
      state.user = action.payload;
    });
    builder.addCase(reqToUserLogin.rejected, (state, action) => {
      state.loader = false;
      state.error = action.error.message;
    });


    builder.addCase(reqToLoginOtp.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToLoginOtp.fulfilled, (state, action) => {
      state.loader = false;
      state.phone = action.payload;
    });
    builder.addCase(reqToLoginOtp.rejected, (state, action) => {
      state.loader = false;
      state.error = action.error.message;
    });
    
  },
});

export const { reqToUserLogout } = userSlice.actions;
export default userSlice.reducer;
