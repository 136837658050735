import { createSlice } from "@reduxjs/toolkit";
import { messages } from "../../constants";
import {
    reqToMarketTrand,
    reqToForeignEligibility,
    reqToLegalDocuments,
    reqToNearbyAmenities,
    reqToPropertyTax,
    reqToROI,
    reqToVISABenefits,
    reqToTabQuestion
 
} from "../services/tabsServices";

// Create initial state
const initialState = {
  loader: false,
  documents: false,
  visa: false,
  nearplace: false,
  trands: false,
  foreignEligibility: false,
  tax: false,
  roi: false,
  tabQue: false,
  error: "",
};

// Create user slice
const tabsSlice = createSlice({
  name: "tabs",
  initialState,
  reducers: {
    reqToUserLogout: () => {
        return initialState;
    },
  },
    extraReducers: (builder) => {
        builder.addCase(reqToMarketTrand.pending, (state) => {
        state.loader = true;
        });
        builder.addCase(reqToMarketTrand.fulfilled, (state, action) => {
        state.loader = false;
        
        state.trands = action.payload;
        });
        builder.addCase(reqToMarketTrand.rejected, (state, action) => {
        state.loader = false;
        state.error = action.error.message;
        });


        builder.addCase(reqToForeignEligibility.pending, (state) => {
            state.loader = true;
        });
        builder.addCase(reqToForeignEligibility.fulfilled, (state, action) => {
            state.loader = false;
            state.foreignEligibility = action.payload;
        });
        builder.addCase(reqToForeignEligibility.rejected, (state, action) => {
            state.loader = false;
            state.error = action.error.message;
        });

        // Documents
        builder.addCase(reqToLegalDocuments.pending, (state) => {
            state.loader = true;
        });
        builder.addCase(reqToLegalDocuments.fulfilled, (state, action) => {
            state.loader = false;
            state.documents = action.payload;
        });
        builder.addCase(reqToLegalDocuments.rejected, (state, action) => {
            state.loader = false;
            state.error = action.error.message;
        });

        // Aminities
        builder.addCase(reqToNearbyAmenities.pending, (state) => {
            state.loader = true;
        });
        builder.addCase(reqToNearbyAmenities.fulfilled, (state, action) => {
            state.loader = false;
            state.nearplace = action.payload;
        });
        builder.addCase(reqToNearbyAmenities.rejected, (state, action) => {
            state.loader = false;
            state.error = action.error.message;
        });

        // Tax
        builder.addCase(reqToPropertyTax.pending, (state) => {
            state.loader = true;
        });
        builder.addCase(reqToPropertyTax.fulfilled, (state, action) => {
            state.loader = false;
            state.tax = action.payload;
        });
        builder.addCase(reqToPropertyTax.rejected, (state, action) => {
            state.loader = false;
            state.error = action.error.message;
        });

         // ROI
         builder.addCase(reqToROI.pending, (state) => {
            state.loader = true;
        });
        builder.addCase(reqToROI.fulfilled, (state, action) => {
            state.loader = false;
            state.roi = action.payload;
        });
        builder.addCase(reqToROI.rejected, (state, action) => {
            state.loader = false;
            state.error = action.error.message;
        });

        // Visabenefit
        builder.addCase(reqToVISABenefits.pending, (state) => {
            state.loader = true;
        });
        builder.addCase(reqToVISABenefits.fulfilled, (state, action) => {
            state.loader = false;
            state.visa = action.payload;
        });
        builder.addCase(reqToVISABenefits.rejected, (state, action) => {
            state.loader = false;
            state.error = action.error.message;
        });

         // reqToTabQuestion
         builder.addCase(reqToTabQuestion.pending, (state) => {
            state.loader = true;
            state.tabQue = false;
        });
        builder.addCase(reqToTabQuestion.fulfilled, (state, action) => {
            state.loader = false;
            state.tabQue = action.payload;
        });
        builder.addCase(reqToTabQuestion.rejected, (state, action) => {
            state.loader = false;
            state.error = action.error.message;
        });

    },
});


export default tabsSlice.reducer;
