import React from 'react'
import Question from '../component/Question'
import SearchBox from '../component/SearchBox'
import Answer from '../component/Answer'
import PlotBox from '../component/PlotBox'
import ScrollX from '../component/ScrollX'

const Chat = () => {
    const ansText = `To find the best real estate near your location, there are a few factors to consider. According to a source on real estate, the location of a property is key to its value. Homes in cities with limited room for expansion tend to be more valuable than those in cities with plenty of room. It's also important to consider the accessibility, appearance, and amenities of a neighborhood, as well as plans for development. Additionally, the proximity of a property to busy roadways may affect its desirability for resale. Acreage often trumps the quality of a house because land tends to increase in value.
    `;
  return (
    <>
    <div className='content'>
      <div className='page-wrapper mx-auto'>
        {/* <PageContent /> */}
        <div className='page-content'>
         <Question text={`I'm looking for a Plot near of Surat.`} />
         <Answer text={ansText} />
         <p className='golden underline mt-2 mb-1'>Plots for Sale in Surat</p>
         <ScrollX>
            <PlotBox />
            <PlotBox />
            <PlotBox />
         </ScrollX>
        </div>
        <SearchBox />
      </div>
    </div>
    </>
  )
}

export default Chat