import React from 'react'

const Answer = (props) => {
  return (
    <div className='answer-wrapper'>
        <div className='answer'>{props.text}</div>
    </div>
  )
}

export default Answer