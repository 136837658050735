import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
import useLocalStorage from 'use-local-storage'
import Button from 'react-bootstrap/Button';
import Header from './layout/Header';
import { Route, Routes, useLocation } from 'react-router-dom';
import Login from './pages/Login';
import Otp from './pages/Otp';
import Details from './pages/Details';
import Chat from './pages/Chat';
import History from './pages/History';
import Properties from './pages/Properties';
import Favorites from './pages/Favorites';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
function App() {
  const location = useLocation();
  const noHeaderPagesArr = ['/login','/otp']
  const defaultLight = window.matchMedia('(prefers-color-scheme: light)').matches;
  const user = useSelector((state) => state?.user?.user);
  const [theme, setTheme] = useLocalStorage('theme', defaultLight ? 'dark' : 'light');
  const navigate = useNavigate();
  const switchTheme = () =>{
    
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme)
  }
  
  useEffect(()=>{
    if(user){
      localStorage.setItem('userData', JSON.stringify(user?.data));
    }
  },[user]);
  useEffect(() => {
    if(localStorage.getItem('userData')) {
        const userData = JSON.parse(localStorage.getItem('userData') || "");
        console.log({userData});
        setTimeout(() => {
          navigate('/')
        }, 500);
    } else {
        setTimeout(() => {
          navigate('/login')
        }, 500);
    }
    
},[localStorage.getItem('userData')]);
  return (
    <div className="App" data-theme={theme}>
      {noHeaderPagesArr.includes(location.pathname) ? '' :  <Header /> } 
      <Routes>
        <Route path="/" element={<Home />}/> 
        <Route path="/chat" element={<Chat />}/> 
        <Route path="/history" element={<History />}/> 
        <Route path="/properties" element={<Properties />}/> 
        <Route path="/favorites" element={<Favorites />}/> 
        <Route path="/details" element={<Details />}/> 
        <Route path="/login" element={<Login />}/> 
        <Route path="/otp" element={<Otp />}/> 
      </Routes>
      {/* <Button onClick={() => switchTheme()} >
        switch to { theme === 'light' ? 'Dark' : 'Light'}
      </Button> */}
      
      
    </div>
  );
}

export default App;
