import React from 'react'
import plotImg from '../assets/img/plot.jpeg'
import { Link } from 'react-router-dom'
import soldImg from '../assets/img/sold.png'
import purchasedImg from '../assets/img/purchased.png'
import vacantImg from '../assets/img/vacant.png'
import rentedImg from '../assets/img/rented.png'
import { FaHeart } from "react-icons/fa";
import PageTabs from './PageTabs'
import "./property.css"

const Property = (props) => {
    const { property } = props;
   
  return (
    <>

    {property?.map((propr)=>{
    return(<><div className="container-property">
    <div className="box">
      <div className="top">
        <img src={propr?.images?.[0]?.image_url} alt={propr?.property_title} loading="lazy" />
        <span><i className="fas fa-heart"></i><i className="fas fa-exchange-alt"></i></span>
      </div>
      <div className="bottom">
        <h3>{propr?.property_title}</h3>
        <h4>{propr?.address}</h4>
        <div className="advants">
          <div>
            <span>Bedrooms</span>
            <div><i className="fas fa-th-large"></i><span>{propr?.Bedrooms}</span></div>
          </div>
          <div>
            <span>Bathrooms</span>
            <div><i className="fas fa-shower"></i><span>{propr?.bathrooms}</span></div>
          </div>
          <div>
            <span>Area</span>
            <div>
              <i className="fas fa-vector-square"></i>
              <span>4300<span>Sq Ft</span></span>
            </div>
          </div>
        </div>
        <div className="price">
          <span>For ${propr?.purchase_type}</span>
          <span>{propr?.price} AED</span>
        </div>
      </div>
    </div>
    </div>
    <PageTabs proprt={propr} /></>)})}
  </>
  )
}

export default Property