import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../helper/axios";
import { apiendpoints, messages, routes } from "../../constants";


//LoginUser
export const reqToChat = createAsyncThunk("reqToChat", async (data) => {
  try {
    const response = await Axios.post(apiendpoints.chat, data);
    return response.data;
  } catch (error) {
    console.error("err =>", error);
   
    throw error;
  }
});

export const reqToInitialMsg = createAsyncThunk("reqToInitialMsg", async () => {
  try {
    const response = await Axios.post(apiendpoints.initialMsg);
    return response.data;
  } catch (error) {
    console.error("err =>", error);
   
    throw error;
  }
});

export const reqToChatHistories = createAsyncThunk("reqToChatHistories", async (data) => {
  try {
    const response = await Axios.get(apiendpoints.getChatHistories+data.phone);
    return response.data;
  } catch (error) {
    console.error("err =>", error);
   
    throw error;
  }
});

