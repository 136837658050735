import { createSlice } from "@reduxjs/toolkit";
import { messages } from "../../constants";
import {
    reqToChat,
    reqToInitialMsg,
    reqToChatHistories
} from "../services/chatServices";

// Create initial state
const initialState = {
  loader: false,
  chat: false,
  initMsg: false,
  histories: [],
  error: "",
};

// Create user slice
const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    reqToUserLogout: () => {
     
      return initialState;
    },
  },
  extraReducers: (builder) => {
    
    //reqToChat
    builder.addCase(reqToChat.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToChat.fulfilled, (state, action) => {
      state.loader = false;
     
      state.chat = action.payload;
    });
    builder.addCase(reqToChat.rejected, (state, action) => {
      state.loader = false;
      state.error = action.error.message;
    });

    // req To InitialMsg
    builder.addCase(reqToInitialMsg.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToInitialMsg.fulfilled, (state, action) => {
      state.loader = false;
     
      state.initMsg = action.payload;
    });
    builder.addCase(reqToInitialMsg.rejected, (state, action) => {
      state.loader = false;
      state.error = action.error.message;
    });

     // req To reqToChatHistories
     builder.addCase(reqToChatHistories.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToChatHistories.fulfilled, (state, action) => {
      state.loader = false;
     
      state.histories = action.payload;
    });
    builder.addCase(reqToChatHistories.rejected, (state, action) => {
      state.loader = false;
      state.error = action.error.message;
    });
    
  },
});


export default chatSlice.reducer;
