import React from 'react'
import PropertyBox from '../component/PropertyBox'
import AddPropertyBtn from '../component/AddPropertyBtn'

const Properties = () => {
  return (
    <div className='content'>
        <div className='page-wrapper mx-auto'>
            <div className='page-content'>
                <h2 className='m-0'>Property Type</h2>
                <hr className='title-underline'/>
                <PropertyBox type='purchased' />
                <PropertyBox type='sold' />
                <PropertyBox type='vacant' />
                <PropertyBox type='rented' />

                <AddPropertyBtn />
            </div>
        </div>
    </div>
  )
}

export default Properties