import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../helper/axios";
import { apiendpoints, messages, routes } from "../../constants";


//LoginUser
export const reqToUserLogin = createAsyncThunk("reqToUserLogin", async (data, thunkAPI) => {
  try {
    const response = await Axios.post(apiendpoints.login, data);
    return response.data;
  } catch (error) {
    console.error("err =>", error);
    
    throw error;
  }
});

export const reqToLoginOtp = createAsyncThunk("reqToLoginOtp", async (data) => {
  try {
    //const response = await Axios.post(apiendpoints.login, data);
    return data;
  } catch (error) {
    console.error("err =>", error);
    
    throw error;
  }
});
