export const apiendpoints = {
  
 
  login: "/api/register",
  chat: "/api/v2/chat",
  initialMsg: "/api/initialMsg",
  getDocument: "/api/get-documents",
  getInvestment: "/api/get-investments",
  getNearPlace: "/api/get-nearplace",
  getTax: "/api/get-tax",
  getTrands: "/api/get-trands",
  getVisa: "/api/get-visa",
  getforeignEligibility: "/api/get-foreignEligibility",
  getChatHistories: "/api/user/histories/",
  
};

export const messages = {
  toast: {
    auth: {
      logout: "Logout Successfully!",
      login: "Welcome Back!",
      register: "User registered successfully! Please check your email!",
      verifyEmail: "Email has been verified!",
      reqToResetPassword: "Please check your email to reset Password!",
      passwordChanged: "Password has been changed!",
    },
    client: {
      addClient: "Add Client request has been send to client's Email!",
      signUpClient: "Details has been submitted!",
    },
    myTeam: {
      addMember: "Add Member request has been send to member's Email!",
      signUpMember: "Details has been submitted!",
    },
    appointment: {
      createAppoinment: "Appoinment has been created!",
    },
    project: {
      addProject: "Project has been added!",
      updateProject: "Project has been updated!",
      addNewMember: "New Member has been added!",
      removeAssignMember: "Member has been removed!",
      addDocument: "Document has been added!",
      removeDocument: "Document has been removed!"
    },
    opportunity: {
      addOpportunity: "Opportunity has been added!",
      updateOpportunity: "Opportunity has been updated!",
      addReminder: "Reminder has been added!",
      updateReminder: "Reminder has been updated!",
      deleteReminder: "Reminder is deleted!",
      addNote: "Note has been added!",
      updateNote: "Note has been updated!",
      deleteNote: "Note is deleted!",
    },
    proposal: {
      addProposal: "Proposal has been added!",
      updateProposal: "Proposal has been updated!",
    },
    profile: {
      updateProfile: "Profile has been updated!",
      updateAgencyProfile: "Profile Agency has been updated!",
      reqToChangePassword: "Password had been changed!",
    },
  },
};
