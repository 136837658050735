import React from 'react'
import plotImg from '../assets/img/plot.jpeg'
import { Link } from 'react-router-dom'

const PlotBox = () => {
  return (
    <div className='plot-box me-2' style={{backgroundImage:`url(${plotImg})`}}>
        <Link to=''>Get More Details </Link>
    </div>
  )
}

export default PlotBox