import React from 'react'
import Question from '../component/Question'
import SearchBox from '../component/SearchBox'
import ScrollX from '../component/ScrollX'
import PlotImg from '../component/PlotImg'
import LikeHeart from '../component/LikeHeart'
import PageTabs from '../component/PageTabs'
import TableVertical from '../component/TableVertical'

const Details = () => {
  return (
    <>
    <div className='content'>
      <div className='page-wrapper mx-auto'>
        {/* <PageContent /> */}
        <div className='page-content'>
          <h2 className='split-end'>More Details <LikeHeart /></h2>
          
          <ScrollX>
            <PlotImg />
            <PlotImg />
            <PlotImg />
            <PlotImg />
          </ScrollX>
          <p className='common-text'>Based on the information provided in the search results, here are more details about a residential plot available for sale in Palsana, Surat, Gujarat:
          </p>
          <PageTabs />
          <TableVertical />

        </div>
        <SearchBox />
      </div>
    </div>
    </>
  )
}

export default Details